
<script setup lang="ts">
import { defineEmits, defineProps, watch, defineModel, Ref } from "vue";

import { DataObject } from "o365-dataobject";
import { $t } from 'o365-utils';

import type { NodeItem } from 'o365-nodedata';
import { MSearch } from "o365-mobile";
import { getOrCreateProcedure } from 'o365-modules'

const procAddOrgUnitToPinnedList = getOrCreateProcedure({
    id:"procAddOrgUnitToPinnedList",
    procedureName: "sstp_System_LookupSelections",
});

const model = defineModel();

const props = defineProps<{
    nodesHierarchy: Ref<NodeItem[]>;
    dataObject: DataObject;
    currentRow: Ref<NodeItem>;
    currentFolder: Ref<string>;
    choosenFolder: NodeItem;
    dsOrgUnits: DataObject;
}>();

const emits = defineEmits<{
    expandFolder: [currentRow: NodeItem, isCalledFromBreadCrumb?: boolean];
    contextChanged: [node: NodeItem];
    goBack: [node: NodeItem];
}>();

const goBack = (node: NodeItem) => emits("goBack", node);
const expandFolder = (node: NodeItem, isCalledFromBreadCrumb?: boolean) => emits("expandFolder", node, true);

// #region Search
const debounce = (func: Function, delay: number) => {
    let timeout: number = 0;

    return (...args) => {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {
            func.apply(this, args)
        }, delay);
    }
} 

const onTypeStop = debounce(async () => {
    props.dataObject.recordSource.whereClause = "[OrgUnit] LIKE '%" + model.value + "%'";
    await props.dataObject.load();
}, 1000);

// aplly current4 orgunit
const orgUnitClicked = async (node: NodeItem) => emits('contextChanged', node);

// trigger search on user stop pringting
watch(model, () => {
    onTypeStop();
});

// #endregion

</script>

<template>
    <header class = "d-block p-1">
        <MSearch :class = "'mt-2'" v-model = "model" />

        <nav v-if ="nodesHierarchy.length > 0" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class = "breadcrumb-item">
                    <button @click = "() => goBack(currentRow)" :disabled="!currentFolder" class = "back-btn">
                        <i class="bi bi-box-arrow-left arrow-back text-black"></i>
                    </button>   
                </li>
                <li v-for="(node, index) in nodesHierarchy" 
                    class="breadcrumb-item" 
                    :class = "index === nodesHierarchy.length -1 ? 'd-none' :''" 
                    >
                    <a @click = "expandFolder(node, true)">
                        {{ node.Name }}
                    </a>
                </li>
            </ol>
        </nav>

        <button v-if="choosenFolder"
            @click ="orgUnitClicked(choosenFolder)"  
            type="button" 
            class="btn btn-primary w-100"
        >
            {{ $t(choosenFolder.OrgUnit) }}
        </button>
    </header>
</template>

<style scoped>
.breadcrumb-item {
    color: rgba(var(--bs-link-color-rgb)) ; 
}


</style>